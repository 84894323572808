import React, { useEffect, useRef } from "react";
import "./mediaMovingLogoStyle.css"


function MediaMovingLogo () {
    const graphListEl = useRef(null);


    const getRandomBetween = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    useEffect(() => {
        const allGs = graphListEl.current.childNodes;

    
        setInterval(() => {
            const match = allGs[getRandomBetween(0, allGs.length)];
        
            if (match) {
                match.style.opacity = match.style.opacity === '0' ? '1' : '0';
            }
        }, 10);

    }, [graphListEl]);

    return (
        <>
            
            <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 585 462"
  width={585}
  height={462}
  preserveAspectRatio="xMidYMid meet"
  style={{
    width: "125%",
    height: "100%",
    transform: "translate3d(0px, 0px, 0px)",
    contentVisibility: "visible"
  }}
>
  <defs>
    <clipPath id="__lottie_element_1687">
      <rect width={585} height={462} x={0} y={0} />
    </clipPath>
  </defs>
  <g clipPath="url(#__lottie_element_1687)">
    <g
      ref={graphListEl}
      className="svg-graph-list"
      fill="rgb(0,0,0)"
      fontSize={22}
      fontFamily="Shne"
      fontStyle="normal"
      fontWeight="normal"
      aria-label=" M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M
      M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M
      M M M M M M M M M M M M M M M M M
      M M M M M M M M M M M M M M M M
      "
      transform="matrix(1,0,0,1,291,232)"
      opacity={1}
      style={{ display: "block" }}
    >
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.238014221191406,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.6619930267334,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940010070800781,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.363988876342773,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357994079589844,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.93400001525879,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.65599822998047,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23198699951172,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.954002380371094,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999114990234,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25200653076172,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54999542236328,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.72198486328125,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.01998901367188,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,-187.70428466796875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-187.70428466796875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-196.3240203857422,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-191.7480010986328,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-173.02601623535156,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-168.4499969482422,-160.30429077148438)"
        opacity="0.9052342857337692"
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-149.72801208496094,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15200805664062,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.43000793457031,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85401916503906,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13199615478516,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.5560073852539,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399200439453,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25800323486328,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.536006927490234,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.959999084472656,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23800277709961,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.661996841430664,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.939996719360352,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.364006042480469,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357992172241211,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.933998107910156,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.6559944152832,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200225830078,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399856567383,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.5300064086914,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25200653076172,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55001068115234,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.8480224609375,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14598083496094,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31797790527344,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61598205566406,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-160.30429077148438)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-160.30429077148438)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-284.94000244140625,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-266.2179870605469,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-261.6419982910156,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-242.9199981689453,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-238.34400939941406,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-219.6219940185547,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-215.04600524902344,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-196.32400512695312,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-191.7480010986328,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-173.0260009765625,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-168.4499969482422,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-149.72799682617188,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15200805664062,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.43000793457031,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85400390625,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13200378417969,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.55599975585938,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399963378906,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25801086425781,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.536006927490234,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.959999084472656,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23800277709961,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.661996841430664,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940013885498047,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.363992691040039,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357990264892578,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.934011459350586,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.6559944152832,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23201370239258,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399856567383,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999114990234,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82798767089844,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55000305175781,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12599182128906,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14601135253906,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31800842285156,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61598205566406,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.3379669189453,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9140167236328,-133.904296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-133.904296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357985496520996,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.934005737304688,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.65599060058594,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200988769531,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399475097656,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999877929688,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82798767089844,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55000305175781,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12599182128906,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-107.50430297851562)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-107.50430297851562)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-149.72801208496094,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15199279785156,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.43000793457031,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85398864746094,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13200378417969,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.55599975585938,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399963378906,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25801086425781,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.5359992980957,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.96000671386719,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23799514770508,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.662004470825195,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940004348754883,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.3639984130859375,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357999801635742,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.934005737304688,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.656002044677734,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23199462890625,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.9539909362793,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999877929688,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.8280029296875,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55000305175781,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12600708007812,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14601135253906,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.4440155029297,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31800842285156,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.6160125732422,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-81.10430908203125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-81.10430908203125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-196.3240203857422,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-191.7480010986328,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-173.02601623535156,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-168.4499969482422,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-149.72801208496094,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15200805664062,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.43000793457031,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85401916503906,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13199615478516,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.5560073852539,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399200439453,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25800323486328,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.536006927490234,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.959999084472656,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23800277709961,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.661996841430664,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.939996719360352,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.364006042480469,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357992172241211,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.933998107910156,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.6559944152832,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200225830078,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399856567383,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.5300064086914,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25200653076172,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55001068115234,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.8480224609375,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14598083496094,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31797790527344,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61598205566406,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,-54.704315185546875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-54.704315185546875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.238014221191406,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.6619930267334,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940010070800781,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.363988876342773,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357994079589844,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.93400001525879,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.65599822998047,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23198699951172,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.954002380371094,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999114990234,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25200653076172,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54999542236328,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.72198486328125,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.01998901367188,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-28.3043212890625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-28.3043212890625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.55601501464844,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399963378906,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25801086425781,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.53599548339844,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.96000289916992,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23799133300781,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.66200065612793,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940001487731934,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.363995552062988,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.358002662658691,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.93400764465332,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.656005859375,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.231998443603516,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399475097656,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999877929688,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.8280029296875,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55000305175781,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12599182128906,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84799194335938,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31800842285156,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.6160125732422,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,-1.904327392578125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,-1.904327392578125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15200805664062,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.42999267578125,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85400390625,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13199615478516,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.5560073852539,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399200439453,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25800323486328,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.53600311279297,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.95999526977539,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.237998962402344,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.6619930267334,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.939993858337402,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.3640031814575195,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.35799503326416,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.93400001525879,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.65599822998047,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200607299805,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.954002380371094,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999114990234,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199127197266,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54999542236328,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84799194335938,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.72201538085938,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02001953125,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.04000854492188,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.3380126953125,24.49566650390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,24.49566650390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-242.92001342773438,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-238.343994140625,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-219.62200927734375,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-215.04598999023438,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-196.32400512695312,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-191.7480010986328,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-173.0260009765625,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-168.45001220703125,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-149.72799682617188,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15200805664062,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.42999267578125,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85400390625,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13200378417969,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.55599975585938,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399963378906,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25799560546875,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.53599548339844,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.96000289916992,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.238006591796875,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.66200065612793,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940003395080566,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.363997459411621,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.358000755310059,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.934005737304688,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.656005859375,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23199462890625,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.954010009765625,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999877929688,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25201416015625,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.8280029296875,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54998779296875,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12600708007812,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84799194335938,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.72198486328125,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.01998901367188,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.04000854492188,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.3380126953125,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.635986328125,50.895660400390625)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,50.895660400390625)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.93398666381836,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.65599822998047,77.295654296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.231990814208984,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.954002380371094,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999114990234,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25200653076172,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,77.295654296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54999542236328,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14601135253906,77.295654296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,77.295654296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,77.295654296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31800842285156,77.295654296875)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.04000854492188,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,77.295654296875)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357985496520996,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.934005737304688,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.65599060058594,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200988769531,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399475097656,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999877929688,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82798767089844,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55000305175781,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12599182128906,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,103.69564819335938)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,103.69564819335938)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-196.3240203857422,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-191.7480010986328,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-173.02601623535156,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-168.4499969482422,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-149.72801208496094,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-145.15200805664062,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-126.43000793457031,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-121.85401916503906,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13199615478516,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.5560073852539,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83399200439453,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25800323486328,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.536006927490234,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.959999084472656,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23800277709961,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.661996841430664,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.939996719360352,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.364006042480469,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.357992172241211,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.933998107910156,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.6559944152832,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200225830078,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399856567383,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.5300064086914,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25200653076172,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55001068115234,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.1259994506836,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.8480224609375,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.42401123046875,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14598083496094,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44398498535156,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.7419891357422,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31797790527344,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.0399932861328,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61598205566406,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.9139862060547,130.09564208984375)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,130.09564208984375)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.655982971191406,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200607299805,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95398712158203,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.5300064086914,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199127197266,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82799530029297,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54999542236328,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12598419189453,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84800720214844,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14601135253906,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.31800842285156,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.04000854492188,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33799743652344,156.49563598632812)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,156.49563598632812)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-103.13201904296875,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-98.55599975585938,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83401489257812,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25799560546875,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.536006927490234,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.959999084472656,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.23800277709961,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.662012100219727,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.939997673034668,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.364006996154785,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.358006477355957,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.933996200561523,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.6559944152832,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.23200225830078,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399856567383,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.5300064086914,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.82798767089844,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.54998779296875,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12599182128906,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84799194335938,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.01998901367188,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.04000854492188,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.33798217773438,182.8956298828125)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.6360168457031,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120361328125,182.8956298828125)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-79.83401489257812,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-75.25799560546875,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-56.5360107421875,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-51.95998764038086,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-33.238006591796875,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-28.66200065612793,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-9.940001487731934,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,-5.364010810852051,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,13.358002662658691,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,17.933992385864258,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,36.656005859375,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,41.231998443603516,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,59.95399475097656,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,64.52999877929688,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,83.25199890136719,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,87.8280029296875,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,106.55000305175781,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,111.12599182128906,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,129.84799194335938,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,134.4239959716797,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,153.14599609375,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,157.7220001220703,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,176.44400024414062,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,181.02000427246094,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,199.74200439453125,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,204.3179931640625,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,223.04000854492188,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,227.61599731445312,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,246.3380126953125,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,250.91400146484375,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,269.635986328125,209.29562377929688)"
        opacity={1}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        transform="matrix(1,0,0,1,274.2120056152344,209.29562377929688)"
        opacity={0}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g
            transform="matrix(1,0,0,1,0,0)"
            opacity={1}
            style={{ display: "block" }}
          >
            <path d=" M46.82899856567383,0 C46.82899856567383,0 68.98500061035156,-59.518001556396484 68.98500061035156,-59.518001556396484 C68.98500061035156,-59.518001556396484 68.98500061035156,0 68.98500061035156,0 C68.98500061035156,0 77.947998046875,0 77.947998046875,0 C77.947998046875,0 77.947998046875,-72.30799865722656 77.947998046875,-72.30799865722656 C77.947998046875,-72.30799865722656 65.15799713134766,-72.30799865722656 65.15799713134766,-72.30799865722656 C65.15799713134766,-72.30799865722656 43.10300064086914,-12.890999794006348 43.10300064086914,-12.890999794006348 C43.10300064086914,-12.890999794006348 21.14900016784668,-72.30799865722656 21.14900016784668,-72.30799865722656 C21.14900016784668,-72.30799865722656 7.955999851226807,-72.30799865722656 7.955999851226807,-72.30799865722656 C7.955999851226807,-72.30799865722656 7.955999851226807,0 7.955999851226807,0 C7.955999851226807,0 17.020000457763672,0 17.020000457763672,0 C17.020000457763672,0 17.020000457763672,-59.518001556396484 17.020000457763672,-59.518001556396484 C17.020000457763672,-59.518001556396484 39.07500076293945,0 39.07500076293945,0 C39.07500076293945,0 46.82899856567383,0 46.82899856567383,0z" />
            <g opacity={1} transform="matrix(1,0,0,1,0,0)" />
          </g>
        </g>
      </g>
      <g
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={4}
        style={{ display: "inherit" }}
      >
        <g transform="scale(0.22,0.22)">
          <g style={{ display: "none" }} />
        </g>
      </g>
    </g>
  </g>
</svg>
        </>
    )
}

export default MediaMovingLogo;